/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getR2rRun = /* GraphQL */ `
  query GetR2rRun($id: ID!) {
    getR2rRun(id: $id) {
      id
      AppleBathOne
      AppleBathTwo
      Immersion
      UpperBath
      PreWetImmersion
      DateTime
      Operator
      Time
      LineName
      Foil {
        id
        Composition
        YieldStrength
        Hardness
        Supplier
        Temper
        Fabrication
        SpecificationsMet
        PurchaseDateTime
        ProductNumber
        FeedRate
        Tension
        Thickness
        SurfaceFinish
        Wetability
        GrainSize
        DyingPenTest
        DateOfManufacture
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      r2rRunFoilId
      owner
    }
  }
`;
export const listR2rRuns = /* GraphQL */ `
  query ListR2rRuns(
    $filter: ModelR2rRunFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listR2rRuns(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        AppleBathOne
        AppleBathTwo
        Immersion
        UpperBath
        PreWetImmersion
        DateTime
        Operator
        Time
        LineName
        Foil {
          id
          Composition
          YieldStrength
          Hardness
          Supplier
          Temper
          Fabrication
          SpecificationsMet
          PurchaseDateTime
          ProductNumber
          FeedRate
          Tension
          Thickness
          SurfaceFinish
          Wetability
          GrainSize
          DyingPenTest
          DateOfManufacture
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        r2rRunFoilId
        owner
      }
      nextToken
    }
  }
`;
export const getFoil = /* GraphQL */ `
  query GetFoil($id: ID!) {
    getFoil(id: $id) {
      id
      Composition
      YieldStrength
      Hardness
      Supplier
      Temper
      Fabrication
      SpecificationsMet
      PurchaseDateTime
      ProductNumber
      FeedRate
      Tension
      Thickness
      SurfaceFinish
      Wetability
      GrainSize
      DyingPenTest
      DateOfManufacture
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listFoils = /* GraphQL */ `
  query ListFoils(
    $filter: ModelFoilFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFoils(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Composition
        YieldStrength
        Hardness
        Supplier
        Temper
        Fabrication
        SpecificationsMet
        PurchaseDateTime
        ProductNumber
        FeedRate
        Tension
        Thickness
        SurfaceFinish
        Wetability
        GrainSize
        DyingPenTest
        DateOfManufacture
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const purchaseByDateTime = /* GraphQL */ `
  query PurchaseByDateTime(
    $PurchaseDateTime: String!
    $sortDirection: ModelSortDirection
    $filter: ModelFoilFilterInput
    $limit: Int
    $nextToken: String
  ) {
    purchaseByDateTime(
      PurchaseDateTime: $PurchaseDateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Composition
        YieldStrength
        Hardness
        Supplier
        Temper
        Fabrication
        SpecificationsMet
        PurchaseDateTime
        ProductNumber
        FeedRate
        Tension
        Thickness
        SurfaceFinish
        Wetability
        GrainSize
        DyingPenTest
        DateOfManufacture
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
