import React, { useState, useContext } from "react"
import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker, MuiPickersUtilsProvider, KeyboardDateTimePicker } from "@material-ui/pickers";
import {createMuiTheme} from "@material-ui/core";
import {ThemeProvider} from "@material-ui/styles";

const General = () => {

  const [time, setTime] = useState(new Date())
  const [operatorValue, setOperator] = useState("");
  const [data, setData] = useState({});

  const operatorHandleChange = (e) => {
    setOperator(e.target.value);
  }

  const Submit = (e) => {
    e.preventDefault();
    setData({'operator': operatorValue,
             'time': time});
    console.log(data)
  };

  const timeHandleChange = (e) => {
    console.log(e)
    setTime(e)

  }

const materialTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#e4913b',
            contrastText: '#fff',
            textColor: 'white'
        },
        secondary: {
            main: '#e4913b',
            contrastText: '#e4913b',
        },
        background: {
            default: '#fff',
        }
    },
    overrides: {
    MuiButton: { // Name of the component ⚛️ / style sheet
      text: { // Name of the rule
        color: 'red', // Some CSS
      },
    },

  }
})

return (
  <div>
    <form onSubmit={Submit}>
      <label>

      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ThemeProvider theme={materialTheme}>
          <DateTimePicker
            variant="inline"
            label="Basic example"
            value={time}
            onChange={timeHandleChange}/>
          </ThemeProvider>
      </MuiPickersUtilsProvider>
        Operator: <input className='input' type='text' value={operatorValue} onChange={operatorHandleChange}/>
        <input type='submit' />
      </label>
    </form>
    <h1>{time.toString()}</h1>
    <p>{JSON.stringify(data)}</p>
  </div>
)
};

export default General;
