import React, { useState, useEffect } from "react";
import awsExports from "../aws-exports";
import Amplify, { API, graphqlOperation } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import "react-datepicker/dist/react-datepicker.css";
import * as queries from "../graphql/queries";
import "../Styles/R2RQuery.css";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css'
import Queries from './Queries'


Amplify.configure(awsExports);

const R2RQuery = () => {

  const [submitted, setSubmitted] = useState(false);
  const [childData, setChildData] = useState()
  const [rows, setRows] = useState([]);

  const columns = [
    { headerName: "id", field: "id" },
    { headerName: "Apple Bath One", field: "AppleBathOne" },
    { headerName: "Apple Bath Two", field: "AppleBathTwo" },
    { headerName: "Immersion", field: "Immersion" },
    { headerName: "Upper Bath", field: "UpperBath" },
    { headerName: "Pre Wet Immersion", field: "PreWetImmersion" },
    { headerName: "Date", field: "DateTime" },
    { headerName: "Time", field: "Time" },
    { headerName: "Operator", field: "Operator" },
    { headerName: "Line Name", field: "LineName" },
  ];

  const Submit = async(e) => {
    e.preventDefault();
    setSubmitted(true);
    setTimeout(() => { setSubmitted(false); }, 2000);
  }

  useEffect(async () => {
    setRows(childData.data.listR2rRuns.items)
  }, [childData])

  return (
    <div>
      <form onSubmit={Submit}>
        <div className="container-one">
          <div className="left">
            <Queries page='listR2rRuns' inputs={['Date', 'Time', 'Operator', 'LineName']} passChildData={setChildData} submitted={submitted} />
          </div>
          <div className="right">
            <input className="submit-query" type="submit" />
          </div>
        </div>
        <div className="container-two">
          <div className='ag-theme-balham'
          style={{width: 1410, height: 600, marginTop:60}}>
          <AgGridReact

            columnDefs={columns}
            rowData={rows}/>
          </div>
        </div>
      </form>
    </div>
  );
};

export default R2RQuery;
