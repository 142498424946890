/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createR2rRun = /* GraphQL */ `
  mutation CreateR2rRun(
    $input: CreateR2rRunInput!
    $condition: ModelR2rRunConditionInput
  ) {
    createR2rRun(input: $input, condition: $condition) {
      id
      AppleBathOne
      AppleBathTwo
      Immersion
      UpperBath
      PreWetImmersion
      DateTime
      Operator
      Time
      LineName
      Foil {
        id
        Composition
        YieldStrength
        Hardness
        Supplier
        Temper
        Fabrication
        SpecificationsMet
        PurchaseDateTime
        ProductNumber
        FeedRate
        Tension
        Thickness
        SurfaceFinish
        Wetability
        GrainSize
        DyingPenTest
        DateOfManufacture
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      r2rRunFoilId
      owner
    }
  }
`;
export const updateR2rRun = /* GraphQL */ `
  mutation UpdateR2rRun(
    $input: UpdateR2rRunInput!
    $condition: ModelR2rRunConditionInput
  ) {
    updateR2rRun(input: $input, condition: $condition) {
      id
      AppleBathOne
      AppleBathTwo
      Immersion
      UpperBath
      PreWetImmersion
      DateTime
      Operator
      Time
      LineName
      Foil {
        id
        Composition
        YieldStrength
        Hardness
        Supplier
        Temper
        Fabrication
        SpecificationsMet
        PurchaseDateTime
        ProductNumber
        FeedRate
        Tension
        Thickness
        SurfaceFinish
        Wetability
        GrainSize
        DyingPenTest
        DateOfManufacture
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      r2rRunFoilId
      owner
    }
  }
`;
export const deleteR2rRun = /* GraphQL */ `
  mutation DeleteR2rRun(
    $input: DeleteR2rRunInput!
    $condition: ModelR2rRunConditionInput
  ) {
    deleteR2rRun(input: $input, condition: $condition) {
      id
      AppleBathOne
      AppleBathTwo
      Immersion
      UpperBath
      PreWetImmersion
      DateTime
      Operator
      Time
      LineName
      Foil {
        id
        Composition
        YieldStrength
        Hardness
        Supplier
        Temper
        Fabrication
        SpecificationsMet
        PurchaseDateTime
        ProductNumber
        FeedRate
        Tension
        Thickness
        SurfaceFinish
        Wetability
        GrainSize
        DyingPenTest
        DateOfManufacture
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      r2rRunFoilId
      owner
    }
  }
`;
export const createFoil = /* GraphQL */ `
  mutation CreateFoil(
    $input: CreateFoilInput!
    $condition: ModelFoilConditionInput
  ) {
    createFoil(input: $input, condition: $condition) {
      id
      Composition
      YieldStrength
      Hardness
      Supplier
      Temper
      Fabrication
      SpecificationsMet
      PurchaseDateTime
      ProductNumber
      FeedRate
      Tension
      Thickness
      SurfaceFinish
      Wetability
      GrainSize
      DyingPenTest
      DateOfManufacture
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateFoil = /* GraphQL */ `
  mutation UpdateFoil(
    $input: UpdateFoilInput!
    $condition: ModelFoilConditionInput
  ) {
    updateFoil(input: $input, condition: $condition) {
      id
      Composition
      YieldStrength
      Hardness
      Supplier
      Temper
      Fabrication
      SpecificationsMet
      PurchaseDateTime
      ProductNumber
      FeedRate
      Tension
      Thickness
      SurfaceFinish
      Wetability
      GrainSize
      DyingPenTest
      DateOfManufacture
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteFoil = /* GraphQL */ `
  mutation DeleteFoil(
    $input: DeleteFoilInput!
    $condition: ModelFoilConditionInput
  ) {
    deleteFoil(input: $input, condition: $condition) {
      id
      Composition
      YieldStrength
      Hardness
      Supplier
      Temper
      Fabrication
      SpecificationsMet
      PurchaseDateTime
      ProductNumber
      FeedRate
      Tension
      Thickness
      SurfaceFinish
      Wetability
      GrainSize
      DyingPenTest
      DateOfManufacture
      createdAt
      updatedAt
      owner
    }
  }
`;
