import React, {useState, useEffect} from "react";
import awsExports from '../aws-exports';
import Amplify, {API, graphqlOperation} from 'aws-amplify';
import * as mutations from '../graphql/mutations'
import '../Styles/R2R.css'
import {purchaseByDateTime} from "../graphql/queries";

Amplify.configure(awsExports)

const Foil = () => {
    const [appleBathOne, setAppleBathOne] = useState();
    const [appleBathTwo, setAppleBathTwo] = useState();
    const [immersion, setImmersion] = useState();
    const [upperBath, setUpperBath] = useState('');
    const [preWetImmersion, setPreWetImmersion] = useState();
    const [dateTime, setDateTime] = useState()
    const [time, setTime] = useState()
    const [operator, setOperator] = useState()
    const [lineName, setLineName] = useState()
    const [data, setData] = useState();
    const [success, setSuccess] = useState(false)

    const appleBathOneHandleChange = (e) => {
        setAppleBathOne(e.target.value);
    }
    const appleBathTwoHandleChange = (e) => {
        setAppleBathTwo(e.target.value);
    }
    const immersionHandleChange = (e) => {
        setImmersion(e.target.value);
    }
    const upperBathHandleChange = (e) => {
        setUpperBath(e.target.value);
    }
    const preWetImmersionHandleChange = (e) => {
        setPreWetImmersion(e.target.value);
    }
    const dateTimeHandleChange = (e) => {
        setDateTime(e.target.value);
    }
    const timeHandleChange = (e) => {
        setTime(e.target.value);
    }
    const operatorHandleChange = (e) => {
        setOperator(e.target.value);
    }
    const lineNameHandleChange = (e) => {
        setLineName(e.target.value);
    }

    const Submit = (e) => {
        e.preventDefault()
        setData(
            {
                "AppleBathOne": appleBathOne,
                "AppleBathTwo": appleBathTwo,
                "Immersion": immersion,
                "UpperBath": upperBath,
                "PreWetImmersion": preWetImmersion,
                "DateTime": dateTime,
                "Time": time,
                "Operator": operator,
                "LineName": lineName
            })
    }

    useEffect(async () => {
        const res = await API.graphql({query: mutations.createR2rRun, variables: {input: data}});
        console.log(res)
        console.log(res.data)
        if (res.data.createR2rRun.id) {
            setSuccess(true)
            setTimeout(() => {
                setSuccess(false);
            }, 4000);
        }
    }, [data])


    return (
        <div>
            <form onSubmit={Submit}>
                <label>
                    <div className='r2r'>
                        <div className='fields'>
                            <p className='text'>Apple Bath One:</p> <input className='input' type='text' value={appleBathOne} onChange={appleBathOneHandleChange}/>
                            <p className='text'>Apple Bath Two:</p> <input className='input' type='text' value={appleBathTwo} onChange={appleBathTwoHandleChange}/>
                            <p className='text'>Immersion:</p> <input className='input' type='text' value={immersion} onChange={immersionHandleChange}/>
                            <p className='text'>Upper Bath:</p> <input className='input' type='text' value={upperBath} onChange={upperBathHandleChange}/>
                            <p className='text'>Pre Wet Immersion:</p> <input className='input' type='text' value={preWetImmersion} onChange={preWetImmersionHandleChange}/>
                            <p className='text'>Date:</p> <input className='input' type='text' value={dateTime} onChange={dateTimeHandleChange}/>
                            <p className='text'>Time:</p> <input className='input' type='text' value={time} onChange={timeHandleChange}/>
                            <p className='text'>Operator:</p> <input className='input' type='text' value={operator} onChange={operatorHandleChange}/>
                            <p className='text'>Line Name:</p> <input className='input' type='text' value={lineName} onChange={lineNameHandleChange}/>
                            {success === true ? (
                                <h1 className={'success'}>Success!</h1>
                            ) : null}

                        </div>
                    </div>
                </label>
                <input className='submit' type='submit'/>
            </form>
        </div>
    )
};

export default Foil;
