import logo from '../xerion.png'

const Header = () => {
  return(
    <div>
      <img className='logo' alt='xerion' src={logo} />
    </div>
  );
}

export default Header;
