import React, { useState, useEffect } from "react";
import awsExports from '../aws-exports';
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../graphql/mutations'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import '../Styles/Foil.css'

import * as queries from "../graphql/queries";
import FoilQuery from "./FoilQuery";

Amplify.configure(awsExports)

function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

function formatDate(date) {
  return [
    padTo2Digits(date.getMonth() + 1),
    padTo2Digits(date.getDate()),
    date.getFullYear(),
  ].join('/');
}

const Foil = () => {
  const [success, setSuccess] = useState(false)
  const [id, setID] = useState('');
  const [productNumber, setProductNumber] = useState('');
  const [purchaseDateTime, setPurchaseDateTime] = useState(new Date());
  const [date, setDate] = useState('')
  const [specificationsMet, setSpecificationsMet] = useState('');
  const [supplier, setSupplier] = useState('');
  const [composition, setComposition] = useState('');
  const [hardness, setHardness] = useState('');
  const [yieldStrength, setYieldStrength] = useState('');
  const [fabrication, setFabrication] = useState('');
  const [surfaceFinish, setSurfaceFinish] = useState('');
  const [temper, setTemper] = useState('');
  const [thickness, setThickness] = useState('');
  const [data, setData] = useState('');
  const [submited, setSubmited] = useState(false);
  const [wetability, setWetability] = useState('');
  const [grainSize, setGrainSize] = useState('');
  const [dyingPenTest, setDyingPenTest] = useState('');
  const [dateOfManufacture, setDateOfManufacture] = useState('');

  const productNumberHandleChange = (e) => {
    setProductNumber(e.target.value);
  }
  const purchaseDateTimeHandleChange = (e) => {
    setPurchaseDateTime(e)
  }
  useEffect( () => {
    setDate(formatDate(purchaseDateTime))
  }, [purchaseDateTime])

  const specificationsMetHandleChange = (e) => {
    setSpecificationsMet(e.target.value);
  }
  const supplierHandleChange = (e) => {
    setSupplier(e.target.value);
  }
  const compositionHandleChange = (e) => {
    setComposition(e.target.value);
  }
  const hardnessHandleChange = (e) => {
    setHardness(e.target.value);
  }
  const yieldStrengthHandleChange = (e) => {
    setYieldStrength(e.target.value);
  }
  const fabricationHandleChange = (e) => {
    setFabrication(e.target.value);
  }
  const surfaceFinishHandleChange = (e) => {
    setSurfaceFinish(e.target.value);
  }
  const temperHandleChange = (e) => {
    setTemper(e.target.value);
  }
  const thicknessHandleChange = (e) => {
    setThickness(e.target.value);
  }
  const wetabilityHandleChange = (e) => {
    setWetability(e.target.value);
  }
  const grainSizeHandleChange = (e) => {
    setGrainSize(e.target.value);
  }
  const dyingPenTestHandleChange = (e) => {
    setDyingPenTest(e.target.value);
  }
  const dateOfManufactureHandleChange = (e) => {
    setDateOfManufacture(e.target.value);
  }
const Submit = (e) => {
  e.preventDefault()
  setData({"ProductNumber": productNumber,
           "PurchaseDateTime": date,
           "SpecificationsMet" : specificationsMet,
           "Supplier" : supplier,
           "Composition" : composition,
           "Hardness" : hardness,
           "YieldStrength" : yieldStrength,
           "Fabrication" : fabrication,
           "SurfaceFinish" : surfaceFinish,
           "Temper" : temper,
           "Thickness" : thickness,
           "Wetability": wetability,
           "GrainSize": grainSize,
           "DyingPenTest": dyingPenTest,
           "DateOfManufacture": dateOfManufacture})

}

  useEffect(async() => {
    const res = await API.graphql({query: mutations.createFoil, variables: {input: data}});
    if(res.data.createFoil.id){
      setSuccess(true)
      setTimeout(() => { setSuccess(false); }, 4000);
    }

    }, [data])

return (
  <div>
    <form onSubmit={Submit}>
      <label>
        <div className='foil'>
        <div  className='blue-category'>
          <p className='text'>Product Number:</p> <input className='input' type='text' value={productNumber} onChange={productNumberHandleChange}/>
          <p className='text'>Purchase Date Time:</p><DatePicker className='input' dateFormat="MM/dd/yyyy" selected={ purchaseDateTime } onChange={ purchaseDateTimeHandleChange } name="startDate" dateFormat="MM/dd/yyyy"/>
          <p className='text'>Specifications Met:</p> <input className='input' type='text' value={specificationsMet} onChange={specificationsMetHandleChange}/>
          <p className='text'>Supplier:</p> <input className='input' type='text' value={supplier} onChange={supplierHandleChange}/>
          <h1>---------------------------</h1>
          <p className='text'>Fabrication:</p> <input className='input' type='text' value={fabrication} onChange={fabricationHandleChange}/>
          <p className='text'>Surface Finish:</p> <input className='input' type='text' value={surfaceFinish} onChange={surfaceFinishHandleChange}/>
          <p className='text'>Temper:</p> <input className='input' type='text' value={temper} onChange={temperHandleChange}/>
          <p className='text'>Thickness:</p> <input className='input' type='text' value={thickness} onChange={thicknessHandleChange}/>
          <h1>---------------------------</h1>
          <p className='text'>Composition:</p> <input className='input' type='text' value={composition} onChange={compositionHandleChange}/>
          <p className='text'>Hardness:</p> <input className='input' type='text' value={hardness} onChange={hardnessHandleChange}/>
          <p className='text'>yieldStrength:</p> <input className='input' type='text' value={yieldStrength} onChange={yieldStrengthHandleChange}/>
          <h1>---------------------------</h1>
          <p className='text'>Wetability:</p> <input className='input' type='text' value={wetability} onChange={wetabilityHandleChange}/>
          <p className='text'>Grain Size:</p> <input className='input' type='text' value={grainSize} onChange={grainSizeHandleChange}/>
          <p className='text'>Dying Pen Test:</p> <input className='input' type='text' value={dyingPenTest} onChange={dyingPenTestHandleChange}/>
          <p className='text'>Date of Manufacture:</p> <input className='input' type='text' value={dateOfManufacture} onChange={dateOfManufactureHandleChange}/>

          {success === true ? (
              <h1 className={'success'}>Success!</h1>
          ) : null}

        </div>
        </div>
      </label>
    <input className='submit' type='submit' />
    </form>

  </div>
)
};

export default Foil;
