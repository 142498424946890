import React from "react";
import { useState, useEffect } from "react";
import { Collapse, List, ListItem } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import NavContext from '../helper/NavContext';
import { useContext } from "react"

function SidebarItem({ item }) {

  const [collapsed, setCollapsed] = useState(true);
  const { title, items, path } = item;
  const [ selected, setSelected ] = useContext(NavContext);
  const [ parentTitle, setParentTitle ] = useState();
  const [ childTitle, setChildTitle] = useState();

  function toggleCollapse() {
    setCollapsed(prevValue => !prevValue);
  }

  function onClick() {
    if (Array.isArray(items)) {
      toggleCollapse();
      setSelected(path)

    }
    else{setSelected(title)}
    console.log(title)
  }

  useEffect(() => {
  }, [selected])

  let expandIcon;
  if (Array.isArray(items) && items.length) {
    expandIcon = !collapsed ? <ExpandLessIcon /> : <ExpandMoreIcon />;
  }

  return (
    <>
      <ListItem onClick={onClick} button dense>
        <div>{title}</div>
        {expandIcon}
      </ListItem>
      <Collapse in={!collapsed} timeout="auto" unmountOnExit>
        {Array.isArray(items) ? (
          <List disablePadding dense>
            {items.map((subItem, index) => (
              <SidebarItem key={`${+ subItem.id}${index}`} item={subItem} />
            ))}
          </List>
        ) : null}
      </Collapse>
    </>
  );
}

function Sidebar({ items }) {
  return (
    <div className='sidebar' >
      <List disablePadding dense>
        {items.map((sidebarItem, index) => (
          <SidebarItem
            key={`${sidebarItem.title}${index}`}
            item={sidebarItem}
          />
        ))}
      </List>
    </div>
  );
}

export default Sidebar
