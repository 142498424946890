import React from "react";
import {useState} from "react"
import Sidebar from "./Components/Sidebar";
import Header from './Components/Header'
import NavContext from "./helper/NavContext"
import Tester from "./Components/Tester"
import Battery from "./Components/Battery"
import Anode from "./Components/Anode"
import General from "./Components/General"
import Cathode from "./Components/Cathode"
import FoilQuery from "./Components/FoilQuery"
import Assembly from "./Components/Assembly"
import Amplify from "aws-amplify"
import Foil from "./Components/Foil"
import R2R from "./Components/R2R"
import awsconfig from "./aws-exports"

import {AmplifySignOut} from '@aws-amplify/ui-react-v1';
import {withAuthenticator} from "@aws-amplify/ui-react-v1"
import '@aws-amplify/ui-react/styles.css';
import R2RQuery from "./Components/R2RQuery";


Amplify.configure(awsconfig)

let data = "";

const items = [
    {
        title: "Foil",
    },

    {
        title: "R2R",
    },

    {
        title: "FoilQuery"
    },

    {
        title: "R2R Query"
    },

    {
        title: "Battery",
        items: [{
            title: "Coin Cell",
            items: [{title: "Anode"}, {title: "Cathode"}, {title: "Assembly"}, {title: "Other"}]
        },
            {title: "Pouch Cell"}]
    },

    {
        title: "LCO Sythesis",
        items: [
            {
                title: "..R2R",
                path: "LCOSynthesis:R2R",
                items:
                    [{title: "....General", path: "LCOSynthesis:R2R:General"},
                        {title: "....Foil", path: "LCOSynthesis:R2R:....Foil"},
                        {title: "....Foil Surface Treatment", path: "LCOSynthesis:R2R:....Foil Surface Treatment"}]
            },

            {
                title: "..Batch",
                items: [{title: "....Foil", path: "LCOSynthesis:Batch:Foil"},
                    {title: "....Foil Surface Treatment", path: "LCOSynthesis:Batch:....Foil Surface Treatmen"}]
            },
        ]
    },


    {
        title: "Test Parameters",
        items: [{title: "subitem"}, {title: "subitem"}],
    },
    {
        title: "Test Results",
        items: [{title: "subitem"}, {title: "subitem"}]
    },
    {
        title: "Extra-recursive",
        items: [{
            title: "subitem",
            items: [{title: "sub-sub-item"}]
        }]
    }

];

function App() {

    const [view, setView] = useState('Home');

    console.log(view)

    return (
        <NavContext.Provider value={[view, setView]}>
            <div className='signout'><AmplifySignOut/></div>
            <Sidebar items={items}/>

            <Header/>

            {view === 'Home' ? (
                <h1 className={'h1-style'}>The pages available for use are Foil and FoilQuery. Use Foil to enter data
                    into the database, and FoilQuery to query the database.</h1>
            ) : null}

            {view === 'LCOSynthesis:R2R:General' ? (
                <General/>
            ) : null}

            {view === 'FoilQuery' ? (
                <FoilQuery/>
            ) : null}

            {view === 'Foil' ? (
                <Foil/>
            ) : null}

            {view === 'LCOSynthesis:Batch:Foil' ? (
                <h1>Foil 2</h1>
            ) : null}

            {view === 'R2R' ? (
                <R2R/>
            ) : null}

            {view === 'R2R Query' ? (
                <R2RQuery/>
            ) : null}


        </NavContext.Provider>
    );
}

export default withAuthenticator(App);
