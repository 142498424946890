import React, {useState, useEffect} from "react";
import object from '../helper/QueryJSON'
import Amplify, {API, graphqlOperation} from "aws-amplify";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import awsExports from "../aws-exports";
import "../Styles/FoilQuery.css";

Amplify.configure(awsExports);

const Queries = (props) => {

    const json = object.queries;
    const [data, setData] = useState(json);

    const foilInputsPath = data[props.page]['Inputs'] //shortcut
    const foilDisabledPath = data[props.page]['Disabled'] //shortcut
    const foilSelectedQueryPath = data[props.page]['SelectedQuery'] //shortcut
    const [parameters, setParameters] = useState([]) //holds arguments passed to filters()
    const [trigger, setTrigger] = useState()


    function filters() {
        let pairsArray = []

        for (const i in arguments) {
            if (i % 2 == 0) { //if i is even. the query is always an even number, and the input is always one after that
                let pair = {[arguments[i]]: {eq: arguments[parseInt(i) + 1]}}   //make query input pair
                pairsArray = [...pairsArray, pair] // add pairs to array for the AND operation
            }
            else { //do nothing if not even
            }
        }
        return pairsArray
    }

    const FoilQueries = async (disabled, input, query) => {
        for (const i in Object.values(disabled)) {
            if (Object.values(disabled)[i] === false) { //for all enabled checkboxes
                setParameters(oldArray => [...oldArray,
                    Object.values([query][0])[i]["Filter"] //sets the query to be passed to filter()
                ])

                setParameters(oldArray => [...oldArray,
                    Object.values(input)[i] //sets the input for filter() (eq: something)
                ])
            }
        }
    }

    useEffect(async () => {
        let output = filters.apply(null, parameters) //output from filters()
        if(output.length == 0) {
            //do nothing
        }
        else{ //run query
            console.log("Output not empty")
            console.log(output)
            const res = await API.graphql({
                query: queries[props.page], variables: {filter: {
                        and: output
                    }
                }});
            if (res['data'][props.page]['items'].length === 0) { //if nothing matches query, show popup
                alert("No data to display")
                setTrigger(1) //triggers parameters to reset
                setTimeout(() => { setTrigger(0); }, 500); //sets trigger back so it can be set again
            } else {
                props.passChildData(res); //passes the data to foilQuery to be displayed in a table
                setTrigger(1)
                setTimeout(() => { setTrigger(0); }, 500);
            }
        }
    }, [parameters])

    useEffect(async () => {
        setParameters([])   //empties parameter array
        console.log(parameters)
    }, [trigger])



    const disabledHandleChange = (e) => {
        setData({
            [props.page]: {
                ...data[props.page],
                Disabled: {
                    ...data[props.page]["Disabled"],
                    [e.target.id]: !e.target.checked //changes the checked to enabled  i think in the json
                }
            }
        })
    };


    const inputHandleChange = (e) => {
        e.preventDefault()
        setData({
            [props.page]: {
                ...data[props.page],
                Inputs: {
                    ...data[props.page]["Inputs"],
                    [e.target.id]: e.target.value //sets input in json i think
                }
            }
        })
    }

    useEffect(() => {
        const onSubmit = () => { //had async
            if (props.submitted === true) { //if submit button is hit in foil query file
                    if (Object.values(foilDisabledPath).includes(false)) {
                        FoilQueries(foilDisabledPath, foilInputsPath, foilSelectedQueryPath); //send shortcuts to foilQueries function in this file
                    } else {
                        alert("Select and input field.");
                    }

            }
        };
        onSubmit();
    }, [props.submitted]);

    return (
        <div>
            {props.inputs.map((prop) => (
                <div>
                    <p className="text_foil_query">{prop}:</p>
                    <input
                        className="input"
                        id={prop}
                        type="text"
                        value={data[props.page][prop]}
                        onChange={inputHandleChange}
                        disabled={foilDisabledPath[prop]}
                    />{" "}
                    <input
                        id={prop}
                        type="checkbox"
                        checked={data[props.page][prop]}
                        onChange={disabledHandleChange}
                    />
                </div>
            ))}
        </div>
    );
};

export default Queries;
