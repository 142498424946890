import * as queries from "../graphql/queries";

const object = {
    queries: {
        listFoils: {
            Inputs: {Date: "", Composition: "", Supplier: ""},
            Disabled: {Date: true, Composition: true, Supplier: true},
            SelectedQuery: {
                Date: {Query: queries.listFoils, Filter: "PurchaseDateTime"},
                Composition: {Query: queries.listFoils, Filter: "Composition"},
                Supplier: {Query: queries.listFoils, Filter: "Supplier"}
            },
        },
        listR2rRuns: {
            Inputs: {Date: "", Time: "", Operator: "", LineName: ""},
            Disabled: {Date: true, Time: true, Operator: true, LineName: true},
            SelectedQuery: {
                Date: {Query: queries.listR2rRuns, Filter: "DateTime"},
                Time: {Query: queries.listR2rRuns, Filter: "Time"},
                Operator: {Query: queries.listR2rRuns, Filter: "Operator"},
                LineName: {Query: queries.listR2rRuns, Filter: "LineName"},
            },
        },
    },
};

export default object

