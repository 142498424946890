import React, { useState, useEffect } from "react";
import awsExports from "../aws-exports";
import Amplify, { API, graphqlOperation } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import "react-datepicker/dist/react-datepicker.css";
import * as queries from "../graphql/queries";
import "../Styles/FoilQuery.css";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css'
import Queries from './Queries'


Amplify.configure(awsExports);

const FoilQuery = () => {

  const [submitted, setSubmitted] = useState(false);
  const [childData, setChildData] = useState()
  const [rows, setRows] = useState([]);
  const columns = [
    { headerName: "id", field: "id" },
    { headerName: "Product Number", field: "ProductNumber" },
    { headerName: "Purchase Date Time", field: "PurchaseDateTime" },
    { headerName: "Specifications Met", field: "SpecificationsMet" },
    { headerName: "Supplier", field: "Supplier" },
    { headerName: "Composition", field: "Composition" },
    { headerName: "Hardness", field: "Hardness" },
    { headerName: "Yield Strength", field: "YieldStrength" },
    { headerName: "Fabrication", field: "Fabrication" },
    { headerName: "Surface Finish", field: "SurfaceFinish" },
    { headerName: "Temper", field: "Temper" },
    { headerName: "Thickness", field: "Thickness" },
    { headerName: "Wetability", field: "Wetability" },
    { headerName: "GrainSize", field: "GrainSize" },
    { headerName: "Dying Pen Test", field: "DyingPenTest" },
    { headerName: "Date Of Manufacture", field: "DateOfManufacture" },
  ];

  const Submit = async(e) => {
    e.preventDefault();
    setSubmitted(true);
    setTimeout(() => { setSubmitted(false); }, 2000);
  }

  useEffect(async () => {
    setRows(childData.data.listFoils.items)
  }, [childData])

  return (
    <div>
      <form onSubmit={Submit}>
        <div className="container-one">
          <div className="left">
            <Queries page='listFoils' inputs={['Date', 'Composition', 'Supplier']} passChildData={setChildData} submitted={submitted} />
          </div>
          <div className="right">
            <input className="submit-query" type="submit" />
          </div>
        </div>
        <div className="container-two">
          <div className='ag-theme-balham'
          style={{width: 1410, height: 600, marginTop:60}}>
          <AgGridReact

            columnDefs={columns}
            rowData={rows}/>
          </div>
        </div>
      </form>
    </div>
  );
};

export default FoilQuery;
